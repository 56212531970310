import React, {useEffect, useState} from "react";
import {BACKEND_API_URL} from "../../../../services/BackendConfig";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";

const PictureCarousel = () => {

    const useStyles = makeStyles((theme) => ({
        picture: {
            flex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            opacity: 0.6,
            color: 'black'
        }
    }));

    const classes = useStyles();

    var [imageKey, setImageKey] = useState(0);
    var [imageSource, setImageSource] = useState('http://localhost/preflightPictures/001.jpg');

    useEffect(() => {

        function getPictureUrl() {
            axios.get(BACKEND_API_URL + '/preflight_picture/' + (imageKey ? imageKey : 0))
                .then((result) => {
                    setImageSource(result.data.Url);
                    setImageKey(result.data.NextKey);
                });
        }
        const interval = setInterval(() => getPictureUrl(), 5000);
        return () => {
            clearInterval(interval);
        }
    });

    return (
        <div>
            <img alt="" src={imageSource} className={classes.picture}/>
        </div>
    );
}

export {PictureCarousel}
