import axios from 'axios';
import {BACKEND_API_URL} from "./BackendConfig";

const restService = '/chat';
const chatId = '061F05D0-CE8C-48A8-8834-43326980B483';

export function sendMessageToDb(message, chat_message_id) {
    if (chat_message_id == null) {
        return axios.post(BACKEND_API_URL + restService + "/message", {chat_id: chatId, chat_message: message})
            .then((response) => (response.data))
            .catch((error) => {
                console.log(error)
                alert('Something went wrong');
            });
    } else {
        return axios.put(BACKEND_API_URL + restService + "/message", {chat_message_id: chat_message_id, chat_message: message})
            .then((response) => (response.data))
            .catch((error) => {
                console.log(error)
                alert('Something went wrong');
            });
    }
}

export function getLatestChatMessages() {
    return axios.get(BACKEND_API_URL + restService + "/" + chatId + "/messages")
        .then((response) => (response.data))
        .catch((error) => {
            console.log(error)
            alert('Something went wrong');
        });
}

export function likeMessage(chat_message_id) {
    return axios.post(BACKEND_API_URL + restService + "/message/" + chat_message_id, {action: 'like'})
        .then((response) => (response.data))
        .catch((error) => {
            console.log(error)
            alert('Something went wrong');
        });
}

export function loveMessage(chat_message_id) {
    return axios.post(BACKEND_API_URL + restService + "/message/" + chat_message_id, {action: 'love'})
        .then((response) => (response.data))
        .catch((error) => {
            console.log(error)
            alert('Something went wrong');
        });
}
