import React, {useState} from "react";
import {ChatContainer} from "../../shared/ChatContainer";
import {sendMessageToDb} from "../../services/ChatService";
import {useAuth} from "../../services/AuthHook";
import {ChatMessageInput} from "../../shared/ChatMessageInput";

const Chat = ({screenSize}) => {
    let chat = useAuth();

    function sendMessage(message) {
        sendMessageToDb(message, null)
            .then((data) => {
                console.log(data.last_modification.timestamp + " " + data.last_modification.user_info.full_name + " " + data.id + " " + data.chat_message + " ");
                chat.sendMessage(data);
            });
    }

    return (
        <div>
            <div>
                <ChatMessageInput screenSize={screenSize} newMessageHandler={sendMessage} />
            </div>
            <ChatContainer screenSize={screenSize} chatMessages={chat.messages}/>
        </div>
    );
}

export default Chat;
