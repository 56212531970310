import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const Countdown = ({setCountdownVisible, screenSize}) => {

    let [countdown, setCountdown] = useState({endTime: Date.parse('2021-01-23T20:20:20'), delta: null});

    const useStyles = makeStyles((theme) => ({
        countdown: {
            flex: 1,
            position: 'absolute',
            top: (screenSize.height - 300) / 2,
            left: (screenSize.width - 500) / 2,
            opacity: 1.0,
            bold: false
        }
    }));

    const classes = useStyles();

    function getCountdownDisplay(delta) {
        let second = 1000;
        let minute = 60 * second;
        let hour = 60 * minute;
        let day = 24 * hour;
        let days = Math.trunc(delta / day);
        let hours = Math.trunc((delta - days * day) / hour);
        let minutes = Math.trunc((delta - days * day - hours * hour) / minute);
        let seconds = Math.trunc((delta - days * day - hours * hour - minutes * minute) / second);
        return (days < 10 ? "0" + days : days) + ":" + (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
    }

    useEffect(() => {

        function setCountDown() {
            let x = {}
            x.endTime = countdown.endTime;
            x.delta = countdown.endTime - Date.now();
            x.showFormat = getCountdownDisplay(x.delta);
            setCountdown(x);
            if (x.delta < 0) {
                setCountdownVisible(false);
            }
        }

        const interval = setInterval(() => setCountDown(), 1000);
        return () => {
            clearInterval(interval);
        }
    });

    return (
        <div className={classes.countdown}>
            <Typography variant="h1" color="textPrimary">Countdown<br/>{countdown.showFormat}</Typography>
        </div>
    );
}

export {Countdown};
