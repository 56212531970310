import React, {useEffect} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {ChatMessage} from "./components/ChatMessage";

const ChatContainer = ({screenSize, chatMessages}) => {

    const useStyles = makeStyles((theme) => ({
        overlay: {
            flex: 1,
            position: 'absolute',
            left: screenSize.width - 360,
            top: 80,
            opacity: 0.7,
            color: 'black',
            width: 360,
            height: screenSize.height - 100,
            'overflow-y': 'scroll'
        }
    }));

    const classes = useStyles();

    let lastElement = null;
    useEffect(() => {
        lastElement.scrollIntoView({behavior: "smooth"});
    }, [chatMessages]);


    return (
        <div className={classes.overlay}>
            {chatMessages.map(message => (
                <ChatMessage key={message.id} chatMessage={message}/>
            ))}
            <div ref={(el) => {
                lastElement = el;
            }}/>
        </div>
    );

}

export {ChatContainer}
