import React, {useEffect, useState} from "react";
import axios from "axios";
import {BACKEND_API_URL} from "../../services/BackendConfig";
import {DataGrid} from '@material-ui/data-grid';
import {Paper} from "@material-ui/core";

const Admin = () => {

    const columns = [
        {field: 'name', headerName: 'Name', width: 250},
        {field: 'email', headerName: 'E-Mail', width: 250},
        {
            field: 'isOnline', headerName: 'Online', width: 117,
            valueGetter: (params) =>
                `${params.getValue('is_online') ? 'Ja' : 'Nein'}`,
        },
        {field: 'last_seen', headerName: 'Zuletzt online', width: 200},
        {field: 'registration_date', headerName: 'Registriert am', width: 200},
        {
            field: 'isVerified', headerName: 'Verifiziert', width: 150,
            valueGetter: (params) =>
                `${params.getValue('verified') ? 'Ja' : 'Nein'}`,
        },
        {
            field: 'isLocked', headerName: 'Gesperrt', width: 150,
            valueGetter: (params) =>
                `${params.getValue('locked') ? 'Ja' : 'Nein'}`,
        },
        {field: 'admin_state', headerName: 'Admin-Status'},
    ];

    const callIntervallInSeconds = 5;
    let [userRows, setUserRows] = useState([]);

    useEffect(() => {
        function getUserData() {
            axios.get(BACKEND_API_URL + '/users')
                .then((response) => {
                    setUserRows(response.data);
                });
        }

        const interval = setInterval(() => getUserData(), callIntervallInSeconds * 1000);
        return () => {
            clearInterval(interval);
        }
    });

    return (
        <Paper style={{height: 1000, width: 1500}}>
            <DataGrid rows={userRows} columns={columns} pageSize={50} density={"compact"} showToolbar={true}/>
        </Paper>
    );
}

export default Admin;
