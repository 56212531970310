import React, {useState} from "react";
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import {Button, Paper} from "@material-ui/core";
import {useAuth} from "../../services/AuthHook";
import {updateUser} from "../../services/UserService";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));

const Account = () => {
    let auth = useAuth();
    const history = useHistory();

    const saveUser = (event) => {
        updateUser(auth.user.user_id, userName, userFirstName)
            .then((data) => {
                let u = auth.user
                u.name = userName;
                u.first_name = userFirstName;
                auth.setUser(u);
                history.push('/');
            });
    }

    const classes = useStyles();
    let [userName, setUserName] = useState(auth.user.name);
    let [userFirstName, setUserFirstName] = useState(auth.user.first_name);

    return (
        <Paper>
            <form className={classes.root} noValidate autoComplete="off">
                <FormControl fullWidth className={classes.margin}>
                    <TextField label="User-Id" defaultValue={auth.user.user_id} InputProps={{readOnly: true}}/>
                </FormControl>
                <FormControl fullWidth className={classes.margin}>
                    <TextField label="Vorname" defaultValue={userFirstName}
                               onChange={e => setUserFirstName(e.target.value)}/>
                </FormControl>
                <FormControl fullWidth className={classes.margin}>
                    <TextField label="Name" defaultValue={userName}
                               onChange={e => setUserName(e.target.value)}/>
                </FormControl>
                <FormControl fullWidth className={classes.margin}>
                    <TextField label="e-Mail" defaultValue={auth.user.e_mail_address} InputProps={{readOnly: true}}/>
                </FormControl>
                <Button variant="contained" color="primary" onClick={saveUser}>Speichern</Button>
            </form>
        </Paper>
    );
}

export default Account;
