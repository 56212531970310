import React, {useState} from "react";
import {Button, Grid, Link, Paper, TextField, Typography} from "@material-ui/core";
import {useHistory} from "react-router";
import {resetPassword} from "../../services/AuthenticationService";
import {useAuth} from "../../services/AuthHook";
import {useLocation} from "react-router-dom";

const ResetPassword = () => {
    let auth = useAuth();
    const history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    let key = query.get("id");
    if (!key || key === '') {
        history.push('/');
    }

    var [password1, setPassword1] = useState("");
    var [password2, setPassword2] = useState("");

    function submit(event) {
        event.preventDefault();
        if (password1 !== password2) {
            alert("Passwörter stimmen nicht überein.");
        } else {
            resetPassword(key, password1).then((data) => {
                auth.signin(data,password1, history);
            });
        }
    }

    return (
        <Grid container spacing={0} justify="center" direction="row">
            <Grid item>
                <Grid container direction="column" justify="center" spacing={2} className="register-form">
                    <Paper variant="elevation" elevation={2} className="login-background">
                        <Grid item>
                            <Typography component="h1" variant="h5">Reset Password</Typography>
                        </Grid>
                        <br/>
                        <Grid item>
                            <form onSubmit={submit}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <TextField type="password" placeholder="Password" fullWidth name="password1" variant="outlined" required
                                                   value={password1} onChange={e => setPassword1(e.target.value)}/>
                                    </Grid>
                                    <Grid item>
                                        <TextField type="password" placeholder="repeat Password" fullWidth name="password2" variant="outlined" required
                                                   value={password2} onChange={e => setPassword2(e.target.value)}/>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary" type="submit" className="button-block">Passwort &Auml;ndern</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        <br/>
                        <Grid item>
                            <Link href="/register" variant="body2">
                                Register
                            </Link>
                            &nbsp;/&nbsp;
                            <Link href="/" variant="body2">
                                Login
                            </Link>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );

}

export default ResetPassword;
