import axios from 'axios';
import {BACKEND_API_URL} from "./BackendConfig";

const restService = '/user';

export function getCurrentUser() {
    return axios.get(BACKEND_API_URL + restService)
        .then((response) => (response.data))
        .catch((error) => {
            console.log(error)
        });
}

export function updateUser(id, name, firstName) {
    return axios.put(BACKEND_API_URL + restService, {user_id: id, name: name, first_name: firstName})
        .then((response) => (response.data))
        .catch((error) => {
            console.log(error)
        });
}
