import React, {useState} from "react";
import {VPlayer} from "../../shared/VPlayer";
import {ChatContainer} from "../../shared/ChatContainer";
import {PictureCarousel} from "./components/PictureCarousel";
import {sendMessageToDb} from "../../services/ChatService";
import {Countdown} from "./components/Countdown";
import {useAuth} from "../../services/AuthHook";
import {ChatMessageInput} from "../../shared/ChatMessageInput";

const Home = ({screenSize}) => {
    let chat = useAuth();
    let [countdownVisible, setCountdownVisible] = useState(true);

    const [chatMessage, setChatMessage] = useState(null);

    let content = '';
    if (countdownVisible) {
        content = <div>
            <PictureCarousel screenSize={screenSize}/>
            <Countdown setCountdownVisible={setCountdownVisible} screenSize={screenSize}/>
        </div>
    } else {
        content = <VPlayer screenSize={screenSize}/>
    }

    function sendMessage(message) {
        sendMessageToDb(message, chatMessage != null ? chatMessage.chat_message_id : null)
            .then((data) => {
                console.log(data.last_modification.timestamp + " " + data.last_modification.user_info.full_name + " " + data.id + " " + data.chat_message + " ");
                chat.sendMessage(data);
            });
        setChatMessage(null);
    }

    function editMessage(message) {
        setChatMessage(message);
    }

    return (
        <div>
            <div>
                {content}
                <ChatMessageInput screenSize={screenSize} newMessageHandler={sendMessage} />
            </div>
            <ChatContainer screenSize={screenSize} chatMessages={chat.messages} editHandler={editMessage}/>
        </div>
    );

}

export default Home;
