import React, {useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";

const ChatMessageInput = ({screenSize, newMessageHandler}) => {

    const useStyles = makeStyles((theme) => ({
        overlay: {
            flex: 1,
            position: 'absolute',
            top: screenSize.height - 100,
            left: 40,
            opacity: 1.0,
            color: 'black',
            width: screenSize.width - 450
        }
    }));


    const [message, setMessage] = useState('');

    function sendMessage() {
        if (message !== '') {
            newMessageHandler(message);
            setMessage('');
        }
    }

    function addEmoji() {

    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    }

    const classes = useStyles();

    return (
        <div className={classes.overlay}>
            <TextField fullWidth label="New Message" value={message}
                       onChange={e => setMessage(e.target.value)} onKeyDown={handleEnterKey}/>
            <Button onClick={sendMessage}>Send</Button>
        </div>
    );
}

export {ChatMessageInput};
