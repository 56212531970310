import React, {useState} from "react";
import {Button, Grid, Link, Paper, TextField, Typography,} from "@material-ui/core";
import {register} from "../../services/AuthenticationService";

const Register = ({handleSubmit}) => {

    var [email, setEmail] = useState("");
    var [name, setName] = useState("");
    var [firstName, setFirstName] = useState("");

    function submit(event) {
        event.preventDefault();
        register(email, name, firstName).then((data) => {
            alert(data);
            if (data.includes('geschickt.')) {
                window.location.href = "/";
            }
        });
    }

    return (
        <Grid container spacing={0} justify="center" direction="row">
            <Grid item>
                <Grid container direction="column" justify="center" spacing={2} className="register-form">
                    <Paper variant="elevation" elevation={2} className="login-background">
                        <Grid item>
                            <Typography component="h1" variant="h5">Register</Typography>
                        </Grid>
                        <br/>
                        <Grid item>
                            <form onSubmit={submit}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <TextField type="email" placeholder="Email" fullWidth name="email" variant="outlined" required autoFocus
                                                   value={email} onChange={e => setEmail(e.target.value)}/>
                                    </Grid>
                                    <Grid item>
                                        <TextField type="firstname" placeholder="Vorname" fullWidth name="email" variant="outlined" required autoFocus
                                                   value={firstName} onChange={e => setFirstName(e.target.value)}/>
                                    </Grid>
                                    <Grid item>
                                        <TextField type="name" placeholder="Name" fullWidth name="name" variant="outlined" required autoFocus
                                                   value={name} onChange={e => setName(e.target.value)}/>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary" type="submit" className="button-block">Registrieren</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        <br/>
                        <Grid item>
                            <Link href="/" variant="body2">
                                Login
                            </Link>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Register;
