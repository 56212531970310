import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Account, Admin, Chat, Home, Login, Register, RequestPasswordReset, ResetPassword} from './scenes';
import {useWindowDimensions} from "./utils";
import {createMuiTheme} from "@material-ui/core/styles";
import Background from "./images/logo_white.png";
import LoggedInFrame from "./shared/LoggedInFrame";
import {PrivateRoute, ProvideAuth} from "./services/AuthHook";

const theme = createMuiTheme({
    palette: {
        type: "dark"
    }
});

function App() {

    const screenSize = useWindowDimensions();

    let sectionStyle = {
        width: 1202,
        height: 648,
        backgroundImage: `url(${Background})`
    }

    return (
        <div className="App-content">
            <section style={sectionStyle}>
                <ProvideAuth>
                    <Router>
                        <Switch>
                            <Route path="/register"><Register/></Route>
                            <Route path="/reset_password"><ResetPassword/></Route>
                            <Route path="/request_reset" exact><RequestPasswordReset/></Route>
                            <Route path="/login"><Login screenSize={screenSize}/></Route>
                            <PrivateRoute path="/chat" exact>
                                <LoggedInFrame theme={theme} screenSize={screenSize}>
                                    <Chat screenSize={screenSize}/>
                                </LoggedInFrame>
                            </PrivateRoute>
                            <PrivateRoute path="/admin" exact>
                                <LoggedInFrame theme={theme} screenSize={screenSize}>
                                    <Admin screenSize={screenSize}/>
                                </LoggedInFrame>
                            </PrivateRoute>
                            <PrivateRoute path="/account" exact>
                                <LoggedInFrame theme={theme} screenSize={screenSize}>
                                    <Account />
                                </LoggedInFrame>
                            </PrivateRoute>
                            <PrivateRoute path="/" exact>
                                <LoggedInFrame theme={theme} screenSize={screenSize}>
                                    <Home screenSize={screenSize}/>
                                </LoggedInFrame>
                            </PrivateRoute>
                        </Switch>
                    </Router>
                </ProvideAuth>
            </section>
        </div>
    );
}

export default App;
