import React, {useState} from "react";
import {Button, Grid, Link, Paper, TextField, Typography} from "@material-ui/core";
import {requestPasswordReset} from "../../services/AuthenticationService";

const RequestPasswordReset = () => {

    var [email, setEmail] = useState("");

    function submit(event) {
        event.preventDefault();
        requestPasswordReset(email).then((data) => {
            alert(data);
        });
    }

    return(
        <Grid container spacing={0} justify="center" direction="row">
            <Grid item>
                <Grid container direction="column" justify="center" spacing={2} className="register-form">
                    <Paper variant="elevation" elevation={2} className="login-background">
                        <Grid item>
                            <Typography component="h1" variant="h5">Request Password Reset</Typography>
                        </Grid>
                        <br/>
                        <Grid item>
                            <form onSubmit={submit}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <TextField type="email" placeholder="Email" fullWidth name="email" variant="outlined" required autoFocus
                                                   value={email} onChange={e => setEmail(e.target.value)} />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary" type="submit" className="button-block">Submit</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        <br/>
                        <Grid item>
                            <Link href="/register" variant="body2">
                                Register
                            </Link>
                            &nbsp;/&nbsp;
                            <Link href="/" variant="body2">
                                Login
                            </Link>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default RequestPasswordReset;
