import React from "react";
import {Logo} from "./components/Logo";
import {MainMenu} from "./components/MainMenu";

const Header = ({screenSize, userCount}) => {
    return (
        <div>
            <Logo/>
            <MainMenu screenSize={screenSize} userCount={userCount}/>
        </div>
    );
}

export {Header}
