import React, {useState} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import {Delete, Edit, ErrorOutline, Favorite, MoreVert, ThumbUp} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {ListItemText, Menu} from "@material-ui/core";
import {likeMessage, sendMessageToDb} from "../../../../../../services/ChatService";

const useStyles = makeStyles({
    root: {
        display: "inline-block"
    },
});

export default function ChatMessageMenu({ownMessage, message}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);


    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleClose();
    }

    const handleDelete = () => {
        if (showDeleteConfirmation) {
            handleClose();
            setShowDeleteConfirmation(false);
        } else {
            setShowDeleteConfirmation(true);
        }
    }

    const handleLike = () => {
        likeMessage(message.chat_message_id);
        handleClose();
    }

    const handleHeart = () => {
        likeMessage(message.chat_message_id);
        handleClose();
    }

    const getDeleteOption = () => ('aaa');
        /*
        if (showDeleteConfirmation) {

            return <MenuItem onClick={handleDelete}><Delete fontSize="small"/>&nbps;<ListItemText primary="Delete"/></MenuItem>
        /*
        } else {
            return <MenuItem onClick={handleDelete}><ErrorOutline fontSize="small"/>&nbps;<ListItemText primary="Click to confirm"/></MenuItem>
        }
         */

    const menuOptionsForOwnMessage = '';
    /*
        <span>
            <MenuItem onClick={handleEdit}>
                <Edit fontSize="small"/>&nbsp;
                <ListItemText primary="Edit"/>
            </MenuItem>
            {getDeleteOption}
        </span>;
    */
    const menuOptionsForForeignMessage =
        <span>
            <MenuItem onClick={handleLike}>
                <ThumbUp fontSize="small"/>
            </MenuItem>
            <MenuItem onClick={handleHeart}>
                <Favorite fontSize="small"/>
            </MenuItem>
        </span>;

    return (
        <span>
            <IconButton className={classes.iconMargin} size="small" onClick={handleClick}>
                <MoreVert fontSize="inherit" color="disabled"/>
            </IconButton>
            <Menu
                id="chat-message-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}>
                {ownMessage ? menuOptionsForOwnMessage : menuOptionsForForeignMessage}
            </Menu>
        </span>
    );
}
