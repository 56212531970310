import {Redirect, Route} from "react-router-dom";
import React, {createContext, useContext, useState} from "react";
import {authenticate} from "./AuthenticationService";
import axios from "axios";
import {getCurrentUser} from "./UserService";
import {BACKEND_CHAT_API_URL} from "./BackendConfig";
import {getLatestChatMessages} from "./ChatService";

function PrivateRoute({children, ...rest}) {
    let auth = useAuth();
    return (
        <Route {...rest} render={({location}) => auth.user ? (
            children
        ) : (
            <Redirect to={{pathname: "/login", state: {from: location}}}/>
        )}
        />
    );
}

const authContext = createContext();

function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [chat, setChat] = useState(null);
    const [messages, setMessages] = useState([]);

    const signin = (email, password, historyHook) => {
        authenticate(email, password)
            .then((data) => {
                if (data) {
                    if (data.error) {
                        console.log(data.error);
                        historyHook.push("/");
                        axios.defaults.headers.common['Authorization'] = '';
                        setUser(null);
                        return null;
                    } else {
                        //console.log('User logged in');
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
                        getCurrentUser().then(
                            (data) => {
                                setUser(data);
                                historyHook.push("/");
                                connect();
                            }
                        );
                        return data;
                    }
                }
            });
    };

    const addMessage = (msg) => {
        setMessages(prevState => [...prevState,msg]);
    }

    const signout = () => {
        disconnect();
        axios.defaults.headers.common['Authorization'] = '';
        setUser(null);
    };

    const getLatestChats = (number, setMessages) => {
        getLatestChatMessages().then((data) => {
            console.log('Connected to the chat and received ' + data.length + ' messages');
            setMessages(data);
        });
    }

    const connect = () => {
        if (chat) {
            disconnect();
        }

        let ws = new WebSocket(BACKEND_CHAT_API_URL);
        ws.onopen = function () {
            getLatestChats(30, setMessages);
        };
        ws.onmessage = function (body) {
            addMessage(JSON.parse(body.data));
        }
        setChat(ws);
    }

    const sendMessage = (msg) => {
        if (chat) {
            chat.send(msg.chat_message_id);
        }
    }

    const updateMessage = (msg) => {
        if (chat) {
            chat.update(msg.chat_message_id);
        }
    }

    const disconnect = () => {
        if (chat) {
            chat.close();
        }
        setChat(null);
    };

    return {
        user,
        setUser,
        signin,
        signout,
        chat,
        connect,
        sendMessage,
        updateMessage,
        disconnect,
        messages
    };
}

export {PrivateRoute, ProvideAuth, useAuth};
