import React from "react";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {useAuth} from "../../../../services/AuthHook";
import Badge from '@material-ui/core/Badge';
import {Mood, ThumbUp} from "@material-ui/icons";
import ChatMessageMenu from "./components/ChatMessageMenu";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 5,
    },
    content: {
        padding: 3,
        paddingLeft: 5,
        paddingRight: 10,
        "&:last-child": {
            paddingBottom: 5
        }
    },
    ownContent: {
        padding: 3,
        paddingLeft: 5,
        paddingRight: 10,
        "&:last-child": {
            paddingBottom: 5
        },
        backgroundColor: "#303030"
    },
    ownTitle: {
        fontSize: 13,
        color: "#A0FF00"
    },
    title: {
        fontSize: 13,
    },
    text: {
        fontSize: 14
    },
    iconMargin: {
        marginLeft: 5,
    },
    badge: {
        marginLeft: 2,
    },
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 15,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

const ChatMessage = ({chatMessage}) => {
    const auth = useAuth();
    const classes = useStyles();

    function isOwnMessage(userId) {
        return auth.user.user_id.toLowerCase() === userId.toLowerCase();
    }

    function getDateTime(timestamp) {
        return timestamp.substr(8, 2) + '.' + timestamp.substr(5, 2) + '. ' + timestamp.substr(11, 5);
    }

    function getContentClass(userId) {
        return isOwnMessage(userId) ? classes.ownContent : classes.content;
    }

    function getTitleClass(userId) {
        return isOwnMessage(userId) ? classes.ownTitle : classes.title
    }

    let chatMessageMenuAndEmoticons = <span>
        <ChatMessageMenu ownMessage={isOwnMessage(chatMessage.user_id)} message={chatMessage}/>
        <ThumbUp fontSize="inherit" color="primary" className={classes.badge}/>
        <Mood fontSize="inherit" color="error" className={classes.badge}/>
    </span>
    ;
    chatMessageMenuAndEmoticons = '';

    return (
        <div>
            <Card className={classes.root} key={chatMessage.chat_message_id}>
                <CardContent className={getContentClass(chatMessage.user_id)}>
                    <Typography variant="subtitle2" className={getTitleClass(chatMessage.user_id)}>
                        {chatMessage.user_name} - {getDateTime(chatMessage.timestamp)}
                        {chatMessageMenuAndEmoticons}
                    </Typography>
                    <Typography className={classes.text}>
                        {chatMessage.message}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}

export {ChatMessage}
