import React, {useEffect, useState} from "react";
import {ThemeProvider} from "@material-ui/styles";
import {Header} from "../Header";
import axios from "axios";
import {BACKEND_API_URL} from "../../services/BackendConfig";

const LoggedInFrame = ({children, theme, screenSize}) => {

    const heartBeatTimeoutInSeconds = 30;
    let [userCount, setUserCount] = useState('1');

    useEffect(() => {
        function sendHeartbeat() {
            axios.post(BACKEND_API_URL + '/session/heartbeat', {current_screen_height: screenSize.height, current_screen_width: screenSize.width})
                .then((response) => {
                    setUserCount(response.data.number_of_users);
                });
        }

        sendHeartbeat();
        const interval = setInterval(() => sendHeartbeat(), heartBeatTimeoutInSeconds * 1000);
        return () => {
            clearInterval(interval);
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <Header screenSize={screenSize} userCount={userCount}/>
            <div style={{marginTop: 80}}>
                {children}
            </div>
        </ThemeProvider>
    );
}

export default LoggedInFrame;
