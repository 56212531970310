import React, {useState} from "react";
import VideoPlayer from 'react-video-js-player';

const VPlayer = ({screenSize}) => {

    let [videoSource] = useState("http://snd.lp8.ch:8080/stream/stream.m3u8");
    let [videoPoster] = useState("");
    return (
        <div>
            <VideoPlayer
                controls={true}
                src={videoSource}
                poster={videoPoster}
                width={screenSize.width - 360}
            />
        </div>
    );
}

export {VPlayer}
